import style from './common.module.css'
import type { IconProps } from '../../../_types'

export function PlayIconOnThumbnail({ title = 'Play', className, color }: IconProps): JSX.Element {
  return (
    <svg
      width="106"
      height="106"
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[className ? className : style.defaultClassName].join(' ')}
    >
      <path
        d="M105 53C105 81.7188 81.7188 105 53 105C24.2812 105 1 81.7188 1 53C1 24.2812 24.2812 1 53 1C81.7188 1 105 24.2812 105 53Z"
        fill="black"
        fillOpacity="0.7"
        stroke={color ?? 'url(#paint0_linear_9619_96434)'}
        strokeWidth="2"
      />
      <path
        d="M71.768 49.1676L45.2702 33.8683C42.3238 32.1667 38.6445 34.295 38.6445 37.6931V68.2918C38.6445 71.69 42.3238 73.8182 45.2702 72.1166L71.768 56.8173C74.7145 55.1157 74.7145 50.8692 71.768 49.1676Z"
        fill={color ?? 'url(#paint1_linear_9619_96434)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear_9619_96434"
          x1="106"
          y1="53"
          x2="-6.04344"
          y2="21.4878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E9B2" />
          <stop offset="1" stopColor="#63CBF8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9619_96434"
          x1="73.9779"
          y1="52.9925"
          x2="36.081"
          y2="43.4452"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E9B2" />
          <stop offset="1" stopColor="#63CBF8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
