import styles from './styles.module.scss'

export interface Props {
  avatar: string
  alt?: string
}

export default function ChannelLogo({ avatar, alt = 'Channel avatar image' }: Props): JSX.Element {
  return (
    <div className={styles.avatar}>
      <img src={avatar} alt={alt} data-id={'channel-avatar-image'} />
    </div>
  )
}
