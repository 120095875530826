import styles from './styles.module.scss'
import { useAppSelector } from '@root/store'
import {
  selectContentLengthById,
  selectContentResumePlaybackById,
} from '@store/contents-slice/_selectors'
import RenderIf from '@root/utils/render-if'
import { getPercentage, isGreaterThanZero } from '@root/utils/connascence'
import { VIDZING_SKY_EARTH_GRADIENT_STRING } from '@shared/constants/root.constants'
import { selectChannelThemeByChannelPathname } from '@store/channel-slice/_selectors'
import useGetResumePlayback from '@hooks/use-get-resume-playback'

interface Props {
  contentId: string
  channelPathname: string
  variant?: 'primaryCard' | 'secondaryCard' | 'bundleList' | 'videoPage'
}

export default function ResumePlaybackProgressBar({
  contentId,
  channelPathname,
  variant = 'primaryCard',
}: Props): JSX.Element {
  // Hook to get resume playback by contentId
  useGetResumePlayback(contentId)
  const resumePlayback = useAppSelector(selectContentResumePlaybackById(contentId))
  const contentLength = useAppSelector(selectContentLengthById(contentId)) ?? 0
  const channelTheme = useAppSelector(selectChannelThemeByChannelPathname(channelPathname))

  const canRenderProgressBar =
    resumePlayback !== undefined &&
    isGreaterThanZero(resumePlayback.lastKnownTime) &&
    isGreaterThanZero(contentLength)

  const cssVariables: Record<string, string> = {
    '--barWidth': `${getPercentage(resumePlayback?.lastKnownTime, contentLength)}%`,
    '--barColour': channelTheme?.buttonColour ?? VIDZING_SKY_EARTH_GRADIENT_STRING,
  }

  return (
    <RenderIf isTrue={canRenderProgressBar}>
      <div className={[styles.wrapper, styles[variant]].join(' ')}>
        <div className={styles.bar} style={cssVariables} />
      </div>
    </RenderIf>
  )
}
