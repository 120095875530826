export interface QueryParams {
  [key: string]: string | number | boolean
}

/**
 * Build a path with query parameters
 * @param basePath
 * @param params
 */
export default function buildPathWithParams(basePath: string, params: QueryParams): string {
  const queryParams = new URLSearchParams()

  for (const key in params) {
    const value = params[key]

    // Skip undefined or null values
    if (value != null) {
      queryParams.append(key, value.toString())
    }
  }

  // Append the query parameters to the base
  return basePath + (queryParams.toString() ? `?${queryParams.toString()}` : '')
}
