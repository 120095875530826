import style from './common.module.css'
import { Hn } from '../../hn'
import { LinkWrapper } from '../../_links/link-wrapper'
import {
  selectChannelPropByChannelPathname as sel,
  selectSubChannelData,
} from '@store/channel-slice/_selectors'
import { useAppSelector as useSel } from '@store/index'
import type { HnVariant } from '../../hn'

export type Props = {
  channelPathname: string
  hn?: HnVariant
  isLink?: boolean
  className?: string
  mainChannelName?: string
}

export function ChannelName({
  channelPathname,
  hn,
  isLink = false,
  className,
  mainChannelName,
}: Props): JSX.Element {
  let displayName = useSel(sel(channelPathname)('displayName')) ?? channelPathname

  // Checks if parent is a super channel and get sub channel data
  const subChannelData = useSel(selectSubChannelData(channelPathname, mainChannelName))
  displayName = subChannelData?.displayName ?? displayName

  return (
    <Hn hn={hn} className={[style.channelName, className].join(' ')}>
      <LinkWrapper
        href={isLink ? `/${channelPathname}` : undefined}
        className={isLink ? style.link : ''}
      >
        {displayName}
      </LinkWrapper>
    </Hn>
  )
}
