import { useDispatch } from 'react-redux'
import { useAppSelector } from '@root/store'
import {
  selectCanWatchContent,
  selectContentResumePlaybackById,
} from '@store/contents-slice/_selectors'
import { useEffect } from 'react'
import { getResumePlaybackByIdThunk } from '@store/contents-slice/_thunks'
import { selectUserId } from '@store/user-slice/_selectors'

export default function useGetResumePlayback(contentId: string): void {
  const dispatch = useDispatch()
  const resumePlayback = useAppSelector(selectContentResumePlaybackById(contentId))
  const contentCanBeWatch = useAppSelector(selectCanWatchContent(contentId))
  const userId = useAppSelector(selectUserId)

  useEffect(() => {
    if (contentCanBeWatch && resumePlayback === undefined && userId) {
      dispatch(getResumePlaybackByIdThunk(contentId))
    }
  }, [contentId, userId, contentCanBeWatch])
}
