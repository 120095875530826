import style from './common.module.css'
import type { IconProps } from '../../../_types'

export function CheckedOutIcon({ title = 'Check out', className }: IconProps): JSX.Element {
  return (
    <svg
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[style.wrapper, className ? className : style.defaultClassName].join(' ')}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3133 11.4369L29.8874 9.39977C30.6282 11.1591 30.9986 13.0111 30.9986 14.9556C30.9986 23.1968 24.239 29.9564 15.9978 29.9564C7.75667 29.9564 0.99707 23.1968 0.99707 14.9556C0.99707 6.71445 7.75667 -0.0451483 15.9978 0.0474489C18.4054 0.0474489 20.8129 0.603032 22.9426 1.7142L21.6463 3.47355C19.8869 2.64017 17.9424 2.17719 15.9978 2.17719C8.96043 2.17719 3.2194 7.91821 3.2194 14.9556C3.2194 21.993 8.96043 27.734 15.9978 27.734C23.0352 27.734 28.7762 21.993 28.7762 14.9556C28.7762 13.7518 28.591 12.5481 28.3133 11.4369ZM8.96045 12.0851C9.23824 11.8073 9.51604 11.7147 9.79383 11.7147C10.0716 11.7147 10.3494 11.8073 10.6272 11.9925L16.183 16.9927L28.2207 1.89939C28.5911 1.43641 29.2392 1.34381 29.7022 1.7142C29.8874 1.89939 30.0726 2.17718 30.0726 2.45498C30.1652 2.82537 30.0726 3.10316 29.8874 3.28835L16.3682 20.2336L9.05305 13.5666C8.59006 13.1963 8.59006 12.5481 8.96045 12.0851Z"
        fill="url(#paint0_linear_5114_538)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5114_538"
          x1="30.9986"
          y1="15.0014"
          x2="-0.69915"
          y2="6.05908"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="hsl(271, 97%, 12%)" />
          <stop offset="1" stopColor="hsl(271, 97%, 12%)" />
        </linearGradient>
      </defs>
    </svg>
  )
}
