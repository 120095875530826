import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay, Navigation } from 'swiper'
import 'swiper/css/pagination'
import 'swiper/css'
import 'swiper/css/navigation'
import { SwiperSettingsInterface } from '@shared/interfaces/layout/slider.interface'
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { Swiper as SwiperClass } from 'swiper/types'
import RenderIf from '@root/utils/render-if'
import ArrowIcon from '@features/_ui/icons/arrow'
import { DirectionsEnum } from '@shared/enums/layout/directions.enum'
import { SliderButtonStylesEnum } from '@shared/enums/slider.enum'
import ChevronIcon from '@ui/icons/chevron'

export interface Props {
  settings: SwiperSettingsInterface
  elements: ReactNode[]
  showButtons?: boolean
  className?: string
  buttonsVariant?: SliderButtonStylesEnum
  isRailContent?: boolean
  callbackOnEnd?: () => void
}

export default function Slider({
  elements,
  settings,
  showButtons = false,
  className,
  buttonsVariant = SliderButtonStylesEnum.CIRCULAR,
  isRailContent = false,
  callbackOnEnd,
}: Props): JSX.Element {
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()
  const navigationPrevRef = useRef(null)
  const navigationNextRef = useRef(null)

  const [_showButtons, setShowButtons] = useState<boolean>(showButtons)
  const [showPrevBtn, setShowPrevBtn] = useState<boolean>(true)
  const [showNextBtn, setShowNextBtn] = useState<boolean>(true)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const toggleButtons = () => {
    // Disable buttons only if the slider is NOT set in loop mode
    if (settings?.loop) return
    setShowPrevBtn(!swiperRef?.isBeginning)
    setShowNextBtn(!swiperRef?.isEnd)

    if (callbackOnEnd && swiperRef?.isEnd) {
      callbackOnEnd()
    }
  }

  const cardsToRender = useMemo(() => {
    return elements.map((element, position) => <SwiperSlide key={position}>{element}</SwiperSlide>)
  }, [elements])

  useEffect(() => {
    setShowNextBtn(!swiperRef?.isEnd)
  }, [elements])

  // Button styles
  const prevButtonStyles = [
    styles.button,
    styles[buttonsVariant],
    styles.prev,
    showPrevBtn ? '' : styles.hideButton,
  ].join(' ')

  const nextButtonStyles = [
    styles.button,
    styles[buttonsVariant],
    styles.next,
    showNextBtn ? '' : styles.hideButton,
  ].join(' ')

  const buttons = (
    <RenderIf isTrue={showButtons}>
      <button ref={navigationPrevRef} onClick={toggleButtons} className={prevButtonStyles}>
        {buttonsVariant === SliderButtonStylesEnum.CIRCULAR ? (
          <ArrowIcon direction={DirectionsEnum.LEFT} />
        ) : (
          <ChevronIcon direction={DirectionsEnum.LEFT} />
        )}
      </button>
      <button ref={navigationNextRef} onClick={toggleButtons} className={nextButtonStyles}>
        {buttonsVariant === SliderButtonStylesEnum.CIRCULAR ? (
          <ArrowIcon direction={DirectionsEnum.RIGHT} />
        ) : (
          <ChevronIcon direction={DirectionsEnum.RIGHT} />
        )}
      </button>
    </RenderIf>
  )

  const sliderStyles = [
    styles.wrapper,
    isRailContent ? styles.railContent : styles.fullWidth,
    className,
  ].join(' ')

  interface SwiperLocalSettings extends SwiperSettingsInterface {
    navigation
    modules
  }

  const breakpoints = settings.breakpoints ? { ...settings.breakpoints } : {}
  // I need to pass these properties here, otherwise the slider falls in an infinite loop
  const swiperConf: SwiperLocalSettings = {
    loop: settings.loop,
    slidesPerView: settings.slidesPerView,
    spaceBetween: settings.spaceBetween,
    slidesPerGroup: settings?.slidesPerGroup ?? 1,
    centeredSlides: settings.centeredSlides,
    modules: [Pagination, Autoplay, Navigation],
    navigation: { prevEl: navigationPrevRef.current, nextEl: navigationNextRef.current },
    breakpoints: breakpoints,
    freeMode: settings?.freeMode ?? false,
    grabCursor: settings.grabCursor,
  }

  return (
    <div className={sliderStyles} ref={wrapperRef}>
      {buttons}
      <Swiper onSwiper={setSwiperRef} {...swiperConf} className={styles.slider}>
        {cardsToRender}
      </Swiper>
    </div>
  )
}
