import style from './common.module.css'
import type { IconProps } from '../../../_types'

export function PlayIconOnGuideButton({ title = 'Play', className }: IconProps): JSX.Element {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={[style.wrapper, className ? className : style.defaultClassName].join(' ')}
    >
      <title>{title}</title>
      <path
        d="M29 15C29 22.732 22.732 29 15 29C7.26801 29 1 22.732 1 15C1 7.26801 7.26801 1 15 1C22.732 1 29 7.26801 29 15Z"
        fill="#1E013A"
        stroke="url(#paint0_linear_5114_554)"
        strokeWidth="2"
      />
      <path
        d="M21.7267 13.7463L12.0227 8.14335C10.9436 7.52019 9.59619 8.2996 9.59619 9.54409V20.75C9.59619 21.9944 10.9436 22.7738 12.0227 22.1507L21.7267 16.5478C22.8058 15.9246 22.8058 14.3694 21.7267 13.7463Z"
        fill="url(#paint1_linear_5114_554)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5114_554"
          x1="30"
          y1="15"
          x2="-1.71041"
          y2="6.08145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E9B2" />
          <stop offset="1" stopColor="#63CBF8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5114_554"
          x1="22.536"
          y1="15.147"
          x2="8.65737"
          y2="11.6506"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53E9B2" />
          <stop offset="1" stopColor="#63CBF8" />
        </linearGradient>
      </defs>
    </svg>
  )
}
