import { intervalToDuration, formatDuration } from 'date-fns'

/**
 * Format duration to xx:xx
 * @param seconds
 */
export default function formatDigitalDuration(seconds: number) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })
  const zeroPad = (num) => String(num).padStart(2, '0')

  const format = ['minutes', 'seconds']
  if (duration.hours) format.unshift('hours')

  return formatDuration(duration, {
    format,
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  })
}
